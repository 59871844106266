import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import {
  BASE_PACK_PATH,
  BATCH_UPLOAD_PACK_ATTACHMENTS_PATH,
  INDEX_PACKS_PATH,
  NEW_PACK_PATH,
  SHOW_PACK_PATH
} from "routes/pack/constants";

const Packs = React.lazy(() => import('pages/packs/index/Packs'));
const NewPack = React.lazy(() => import('pages/packs/new/NewPack'));
const ShowPack = React.lazy(() => import('pages/packs/show/ShowPack'));
const BatchUploadAttachments = React.lazy(() => import('pages/packs/batchUploadAttachments/BatchUploadAttachments'));

export const packRoutes = {
  path: BASE_PACK_PATH,
  name: '包裹列表',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_PACKS_PATH,
      exact: true,
      name: '包裹',
      component: Packs,
      route: PrivateRoute,
    },
    {
      path: NEW_PACK_PATH,
      exact: true,
      name: '添加新包裹',
      component: NewPack,
      route: PrivateRoute,
    },
    {
      path: BATCH_UPLOAD_PACK_ATTACHMENTS_PATH,
      exact: true,
      name: '批量上传包裹图片',
      component: BatchUploadAttachments,
      route: PrivateRoute,
    },
  ],
};

export const hiddenPackRoutes = {
  children: [
    {
      path: SHOW_PACK_PATH,
      exact: true,
      name: '包裹展示',
      component: ShowPack,
      route: PrivateRoute,
    }
  ]
}
